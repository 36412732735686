<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-row>
          <v-spacer></v-spacer>
          <v-menu v-model="menu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="text-capitalize" v-bind="attrs" v-on="on" text>
                <v-icon left>mdi-translate</v-icon>
                {{ currentLanguageText }}
                <v-icon small right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(lang, index) in languages"
                :key="index"
                @click="changeLanguage(lang.value)"
              >
                <v-list-item-title>{{ lang.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></v-row
        >

        <v-card-text class="text-center">
          <v-avatar size="70" class="mb-2">
            <img src="@/assets/images/optimy-logo.png" alt="" />
          </v-avatar>
          <h2 class="font-weight-bold mb-4">Optimy Pos</h2>
          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("signInPage.signin2youacc") }}
          </h6>

          <v-form>
            <v-alert v-model="snackbar" dense color="red lighten-2" dark>
              {{ $t("signInPage.incorrectPassOrEmail") }}
            </v-alert>
            <v-text-field
              :label="$t('signInPage.email')"
              v-model="email"
              :error-messages="emailErrors"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            />

            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :label="$t('signInPage.password')"
              :counter="10"
              v-model="password"
              @click:append="show = !show"
              :error-messages="passwordErrors"
              required
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            ></v-text-field>
            <v-checkbox v-model="checkbox1" :label="$t('signInPage.rememberMe')"></v-checkbox>
            <v-btn class="mb-4" @click="login" block color="primary" dark>
              <v-icon left>mdi-login</v-icon>
              {{ $t("signInPage.signIn") }}</v-btn
            >
            <!-- <v-btn
                            class="mb-4"
                            @click="googleSignIn"
                            block
                            color="#DB4437"
                            dark
                        >
                            <v-icon left>
                                mdi-google
                            </v-icon>

                            Sign In
                        </v-btn> -->
            <div class="d-flex justify-center flex-wrap">
              <v-btn
                text
                small
                color="primary"
                to="/app/sessions/sendmail"
                class="mb-2"
                >{{ $t("signInPage.forgotPassword") }}</v-btn
              >
            </div>
            <!-- <div class="d-flex justify-start flex-wrap">
              <v-btn
                text
                small
                class="no-uppercase"
                color="primary"
                to="/app/sessions/signup"
                >New to our POS?</v-btn
              >
            </div> -->
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
// import firebase from "firebase/app";
// import { mapGetters, mapActions } from "vuex";
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "login",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Login",
  },

  mixins: [validationMixin],

  validations: {
    email: {
      required,
      email,
    },
    password: { required },
  },
  data() {
    return {
      domain: BASEURL,
      show: false,
      snackbar: false,
      checkbox1: true,
      checkbox2: false,
      email: "",
      password: "",
      loading: false,
      menu: false,
      selectedLocale: this.$i18n.locale,
      languages: [
        { text: "English", value: "en" },
        { text: "Chinese", value: "zh-CN" },
        { text: "Bahasa Melayu", value: "ms" },
      ],
    };
  },
  computed: {
    // ...mapGetters(['loggedInUser', 'error'])
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t('signInPage.validMail'));
      !this.$v.email.required && errors.push(this.$t('signInPage.emailRequire'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t('signInPage.passwordRequire'));
      return errors;
    },
    currentLanguageText() {
      const lang = this.languages.find((l) => l.value === this.$i18n.locale);
      return lang ? lang.text : "Select Language";
    },
  },
  methods: {
    changeLanguage(locale) {
      this.selectedLocale = locale; // Update selected locale
      this.$i18n.locale = locale; // Change language in the i18n instance
      localStorage.setItem("locale", locale); // Persist the selected language in local storage
      this.menu = false; // Close the menu after selection
    },
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const params = new URLSearchParams();
        params.append("login", "done");
        params.append("email", this.email);
        params.append("password", this.password);

        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            if (response.data.status == "1") {
              var CryptoJS = require("crypto-js");

              // Encrypt
              var ciphertext = CryptoJS.AES.encrypt(
                JSON.stringify(response.data.user),
                "secret key 123"
              ).toString();

              localStorage.setItem("userInfo", ciphertext);
              setTimeout(() => {
                this.loading = false;
                this.$router.push("/");
              }, 500);
            } else {
              this.snackbar = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // formSubmit() {
    //     this.login({email: this.email, password: this.ePassword})
    // },
    // googleSignIn() {
    //     const provider = new firebase.auth.GoogleAuthProvider()

    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then((result) => {
    //             const newUser = {uid: result.user.uid}
    //             localStorage.setItem('userInfo', JSON.stringify(newUser))
    //             this.$router.push('/')
    //         })
    //         .catch((err) => {
    //             alert('Oops' + err.message)
    //         })
    // }
  },
  watch: {
    // loading (val) {
    //   if (!val) return
    //   setTimeout(() => (this.loading = false), 2000)
    // },
    // loggedInUser(val) {
    //     if (val && val.uid && val.uid.length > 0) {
    //         // this.makeToast("success", "Successfully Logged In");
    //         console.log('logged in successfully ')
    //         this.loading = true
    //         setTimeout(() => {
    //             this.$router.push('/')
    //         }, 500)
    //     }
    // },
    // error(val) {
    //     if (val != null) {
    //         // this.makeToast("warning", val.message);
    //     }
    // }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // background-image:url('../../../assets/images/login-background.jpg');
  background-image: linear-gradient(to bottom, transparent, #837960),
    url("../../../assets/images/login-background.jpg");
  background-color: #242939 !important;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.no-uppercase {
  text-transform: unset !important;
}
.v-menu__activator {
  cursor: pointer;
}
</style>
